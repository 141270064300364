<template>
  <div class="auth-wrapper">
    <div class="container-fluid h-100">
      <b-row class="flex-row h-100 bg-white">
        <div class="col-xl-4 col-lg-6 col-md-7 p-0">
          <div class="authentication-form mx-auto">
            <div class="register-top">
              <b-link :to="{ name: 'home' }">
                <b-img src="@/assets/img/logo-m0.png" class="logo"/>
              </b-link>
              <h3 class="slogan">
                {{$t('data.register_creer_votre_compte')}}
              </h3>
            </div>

            <form action="" @submit.prevent="sendForm">
              <div class="mb-5">
                <b-row>
                  <b-col
                    ><div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        :placeholder="$t('data.profile_nom')"
                        required=""
                        v-model="nom"
                      />
                      <i class="ik ik-user"></i></div
                  ></b-col>
                  <b-col
                    ><div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        :placeholder="$t('data.profile_prenom')"
                        v-model="prenom"
                      />
                      <i class="ik ik-user"></i></div
                  ></b-col>
                </b-row>
                <b-row>
                  <b-col
                    ><div class="form-group">
                      <input
                        type="tel"
                        class="form-control"
                        :placeholder="$t('data.occupation_numero_telephone')"
                        required=""
                        v-model="tel"
                      />
                      <i class="ik ik-phone"></i></div
                  ></b-col>
                  <b-col
                    ><div class="form-group">
                      <input
                        type="email"
                        class="form-control"
                        :placeholder="$t('data.occupation_email')"
                        v-model="email"
                      />
                      <i class="ik ik-mail"></i></div
                  ></b-col>
                </b-row>
                <div class="form-group">
                  <input
                    type="password"
                    class="form-control"
                    :placeholder="$t('data.login_mot_de_passe')"
                    required=""
                    v-model="mdp"
                  />
                  <i class="ik ik-lock"></i>
                </div>
				<div class="form-group" v-if="genre == 'entreprise'">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Nom de l'entreprise"
                    v-model="entreprise"
                  />
                  <i class="ik ik-lock"></i>
                </div>
                <div class="d-flex justify-content-center statut-client">
                  <div class="form-radio">
                    <div class="radio radio-inline">
                      <label class="statut-item">
                        <input
                          type="radio"
                          name="radio"
                          v-model="genre"
                          value="homme"
                          checked
                        />
                        <i class="helper"></i> {{$t('data.register_homme')}}
                      </label>
                    </div>
                    <div class="radio radio-inline">
                      <label>
                        <input
                          type="radio"
                          name="radio"
                          v-model="genre"
                          value="femme"
                        />
                        <i class="helper"></i> {{$t('data.register_femme')}}
                      </label>
                    </div>
                    <div class="radio radio-inline">
                      <label>
                        <input
                          type="radio"
                          name="radio"
                          v-model="genre"
                          value="entreprise"
                        />
                        <i class="helper"></i> {{$t('data.register_entreprise')}}
                      </label>
                    </div>
                  </div>
                </div>
                <hr />
              </div>
              <div class="row">
                <div class="col-12 text-left">
                  <label class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      required
                    />
                    <span class="custom-control-label accept" style="font-size:.7em;color: #979797;">je déclare avoir lu et accepté les <b-button v-b-modal.modal-scrollable class="remouve-defalut-class">politiques de confidentialité</b-button> et <b-button v-b-modal.modal-scrollable class="remouve-defalut-class">Conditions d'utilisation</b-button> du Maisonier</span>
                    <!--<span class="custom-control-label accept">
                      La famille FlySoft entreprise est autorisée à m'envoyer des informations au travers d'emails <b>personnalisés</b> sur ses produits et services. Pour 
                      plus d'informations ou pour vous inscrire à tout moment, consultez notre <b>Politique de confidentialité</b>
                    </span>-->
                  </label>
                </div>
              </div>
              <div class="sign-btn"> 
                <span>
                  {{$t("data.register_vous_un_compte")}}
                  <b-link :to="{ name: 'login' }">{{$t("data.register_connectez_vous")}}</b-link>
                </span>
                <b-button type="submit" :disabled="submitted" class="inscription-btn">
                  {{$t('data.register_creer_un_compte')}} <b-spinner v-if="submitted" small></b-spinner>
                </b-button>
              </div>
            </form>
            <div class="register">
            </div>
            
            <!--<div>
              <span style="font-size:.7em;color: #979797;">
                En cliquant sur <b style="color:rgba(1, 121, 201, 0.842);">{{$t('data.register_creer_un_compte')}}</b>, je déclare avoir lu et accepté les <b style="color:rgba(1, 121, 201, 0.842);">Politiques de confidentialité</b> et <b style="color:rgba(1, 121, 201, 0.842);">Conditions d'utilisation</b> de FlySoft Engineering
              </span>
            </div>-->
            <div>
              <span style="font-size:.7em;color: #979797;position:absolute;bottom:10px;">
                Protégé pa reCAPTCHA et soumis aux <b style="color:rgba(1, 121, 201, 0.842);">Politiques de confidentialité</b> et <b style="color:rgba(1, 121, 201, 0.842);">Conditions d'utilisation de Flysoft Engineering</b>
              </span>
            </div>
          </div>
        </div>
        <b-col xl="8" lg="6" md="5" class="d-md-block d-lg-block d-none p-0">
          <b-carousel :interval="4000" indicators background="#ababab">
            <b-carousel-slide class="responsive border-0">
              <template #img>
                <div class="titre-overlay">
                  <h1 class="titre"><blockquote class="">{{$t("data.slide_1_titre")}}</blockquote></h1>
                  <p class="description">
                    {{$t('data.slide_1_description')}}
                  </p>
                </div>
                <div class="overlay"></div>
                <img class="w-100 image" src="@/assets/img/patrimoine2.jpg" />
              </template>
            </b-carousel-slide>
            <b-carousel-slide class="responsive border-0">
              <template #img>
                <div class="titre-overlay">
                  <h1 class="titre"><blockquote class="">{{$t('data.slide_2_titre')}}Ne perdez plus aucune information</blockquote></h1>
                  <p class="description">{{$t('data.slide_2_description')}}</p>
                </div>
                <div class="overlay"></div>
                <img class="w-100 image" src="@/assets/img/patrimoine.jpg" />
              </template>
            </b-carousel-slide>
            <b-carousel-slide class="responsive border-0">
              <template #img>
                <div class="titre-overlay">
                  <h1 class="titre"><blockquote class="">{{$t('data.slide_3_titre')}}</blockquote></h1>
                  <p class="description">{{$t('data.slide_3_description')}}</p>
                </div>
                <div class="overlay"></div>
                <img class="w-100 image" src="@/assets/img/appart-sejour (2).jpg" />
              </template>
            </b-carousel-slide>
          </b-carousel>
        </b-col>
      </b-row>
    </div>
        <!--MODAL POUR LES CONDITIONS D'UTILISATION + POLITIQUE DE CONFIDENTIALITE -->
      <b-modal id="modal-scrollable" scrollable title="Conditions d'utilisation & politique de confidentialité" hide-footer no-close-on-backdrop>
        <h3 class="font-weight-bold">Conditions générales d'utilisation</h3>
        <h4>Conditions de service pour les utilisateurs</h4>
        <p class="my-4">L'article 23 des présentes conditions contient une convention d'arbitrage et une clause de renonciation aux recours collectifs qui s'appliquent à toute procédure engagée contre Flysoft Engineering. Veuillez les lire attentivement. </p>
        <p>Dernière mise à jour : <span>06 janvier 2022 </span></p>
        <p>Merci de choisir Flysoft!</p>
        <p class ="pt-1">Les présentes Conditions générales (les « conditions ») sont un contrat juridique contraignant entre vous et Flysoft Engineering qui régit votre utilisation des sites web, applications et autres offres de Flysoft Engineering (appelés collectivement, la « plateforme Maisonier»). Lorsqu'ils sont utilisés dans ces conditions, les termes « Maisonier», « nous », « notre » et « nos » font référence à l'entité Flysoft Engineering mentionnée dans l' annexe 1 avec laquelle vous concluez un contrat. <br>
          La plateforme Maisonier offre un espace en ligne permettant aux utilisateurs (« Membres ») de gérer leur bien, de publier, d'offrir, de rechercher et de réserver des services . Les Membres qui publient et offrent des services sont des « Bailleurs » et les Membres qui recherchent, réservent ou utilisent des services sont des « Locataires». Les Bailleurs offrent des hébergements (« Hébergements »), gérer leur patrimoine immobilier, ainsi qu'une variété de services de location et autres (collectivement, les « Services de location », et chaque offre de service locative, une « Annonce »). Vous devez créer un compte pour accéder à de nombreuses fonctionnalités de la plateforme Maisonier et les utiliser. Vous devez également veiller à ce que les informations de votre compte demeurent exactes. En tant que fournisseur de la plateforme Maisonier , Flysoft Engineering ne possède, ne contrôle, n'offre et ne gère ni les annonces ni les services de location. Flysoft Engineering n'est pas partie aux contrats conclus directement entre les Bailleurs et les locataires, et Flysoft Engineering n'est pas courtier immobilier ni assureur. Flysoft Engineering n'agit pas en qualité de mandataire d'un quelconque Membre, sauf comme le prévoient les Conditions de service relatives aux paiements (ci-après dénommées « Conditions de paiement »). Pour en savoir plus sur le rôle de Flysoft Engineering, voir l'article 16. <br>
          Nous appliquons d'autres Conditions et Politiques complémentaires à ces conditions comme notre Politique de confidentialité, laquelle décrit notre collecte et utilisation des données personnelles, et nos Conditions de paiement, qui régissent tous les services de paiement fournis aux membres par les entités de paiement Maisonier (« Maisonier Payments »). <br>
          Si vous êtes Bailleur, vous êtes tenu de comprendre, de respecter toutes les lois , réglementations et tous les règlements et contrats avec des tiers qui s'appliquent à vos Services de location. 
        </p>
        <h4 class="font-weight-bold">Conditions générales des locataires </h4>
        <div>
          <h4>1. Notre Mission</h4>
          <p> Notre mission est de construire un monde où chacun puisse se sentir chez soi, n'importe où. Des cabanes aux châteaux en passant par la gestion , parcourez des millions d'Annonces pour trouver celles qui correspondent à votre façon de vivre. Pour en savoir plus sur une Annonce, consultez la description et les photos, le profil de du Bailleur et les commentaires des utilisateurs. Si vous avez des questions, envoyez un message au Bailleur.</p>
        </div>
        <div>
          <h4>2. Recherche et réservation sur Maisonier .</h4>
          <p><span class="font-weight-bold"> 2.1 Recherche.</span> Vous pouvez rechercher des logements ou services de location en utilisant des critères tels que le type de logement, l’adresse du logement, les caractéristiques de ce dernier . Vous pouvez également utiliser des filtres pouraffiner vos résultats de recherche. Les résultats de la recherche sont basés sur leur pertinence par rapport à votre recherche et à d'autres critères. La pertinence tient compte de facteurs tels que le prix, la disponibilité, les commentaires, le service à la clientèle et l'historique des annulations, la popularité, les locataires précédents et les annonces sélectionnées, les exigences du bailleur (par exemple, nombre de jours minimum ou maximum de location), et plus. Pour en savoir plus sur les résultats de recherche, consultez notre Centre d'aide.
          </p>
        </div>
        <div>
          <p><span class="font-weight-bold">2.2 Réservation.</span>
            Lorsque vous réservez une Annonce, vous acceptez de payer tous les frais relatifs à votre réservation, y compris le prix annoncé pour l'Annonce, les frais applicables tels que les frais de service du Maisonier , les frais perçus en personne, les taxes et tout autre élément identifié lors de la commande (collectivement, le <span class="font-weight-bold">« prix total »</span>). Vous acceptez également que Maisonier Payments
            puisse facturer et collecter, lors du règlement, toute caution indiquée. Lorsque vous recevez la confirmation de réservation, un contrat de service de location (parfois appelé contrat de bail) est conclu directement entre vous et le bailleur. Les conditions d'annulation et tous les autres règlements, normes, politiques ou exigences identifiés dans l'Annonce ou lors de la commande font partie de votre contrat avec le bailleur. Sachez que certains Bailleurs travaillent avec un co-bailleur, entreprise ou en tant que membre d'équipe pour fournir leurs Services de location
          </p>
        </div>
        <div>
          <p><span class="font-weight-bold">2.3 Réservations d'hébergement.</span>
            Une réservation d'hébergement est une licence limitée en vue d'accéder à l'hébergement, de l'occuper et de l'utiliser. Le bailleur se réserve le droit de réintégrer l'hébergement pendant votre séjour, le cas échéant si :(i) cela est raisonnablement nécessaire, (ii) cela est autorisé en vertu de votre contrat avec le bailleur, et (iii) cela est conforme au droit applicable. Si vous restez après le jour de départ, le bailleur a le droit de vous faire partir d'une manière
            conforme au droit applicable, y compris en vous imposant des sanctions raisonnables pour séjour indûment prolongé. Vous ne pouvez pas dépasser le nombre maximal de jours autorisés.
          </p>
        </div> 
        <div>
          <h4>3. Annulations, problèmes de location, remboursements et modifications de réservation</h4>
          <p><span class="font-weight-bold"> 3.1 Annulations, problèmes de location et remboursements.</span> En général, si, en tant que locataire, vous annulez une réservation, le montant qui vous est remboursé est déterminé par les conditions d'annulation applicables à cette réservation. Mais, dans
            certaines situations, d'autres conditions sont prioritaires et déterminent le montant qui vous est remboursé. Si un événement indépendant de votre volonté vous oblige à annuler une réservation, vous êtes susceptible d'être admissible à un
            remboursement partiel ou intégral en vertu de notre Politique sur les cas de force majeure. Si le bailleur annule ou si vous rencontrez un problème de location (tel que défini dans notre Politique de remboursement des locataires), vous pourriez être
            admissible à une aide pour une nouvelle réservation ou à un remboursement partiel ou complet en vertu de la Politique de remboursement des locataires. Voir chaque
            politique pour plus de détails sur ce qui est couvert et sur le remboursement s'appliquant dans chaque situation.</p>
        </div>
        <div>
          <p><span class="font-weight-bold">3.2 Modifications de réservation.</span>
            Les locataire et les Bailleurs sont responsables de toute modification de réservation qu'ils acceptent d'apporter par le biais de la plateforme Maisonier ou demandent au service à la clientèle Maisonier d'effectuer
            en leur nom <span>(« modifications de réservation »)</span>, et acceptent de payer tout montant,frais ou taxes supplémentaires associés à toute modification de réservation.
          </p>
        </div>
        <div>
          <p><span class="font-weight-bold">4. Vos responsabilités et votre acceptation des risques.</span></p>
        </div>
        <div>
          <p><span class="font-weight-bold">4.1 Vos responsabilités. </span>
            Vous êtes responsable de vos propres actes et omissions et êtes également responsable des actes et omissions de toute personne que vous invitez à participer ou à qui vous fournissez un accès dans le cadre d'une location ou
            d'un autre Service de location. Par exemple, cela signifie : (i) que vous avez la responsabilité de laisser un logement (et les biens personnels associés) dans l'état dans lequel vous l'avez trouvé à votre arrivée, et (ii) que vous devez agir avec
            intégrité, traiter les autres avec respect et vous conformer aux lois applicables en tout temps. Si vous réservez pour un locataire supplémentaire qui est mineur ou si vous faites venir un mineur dans un Service de location, vous devez être légalement
            autorisé à agir au nom du mineur et vous êtes seul responsable de la supervision de ce mineur.
          </p>
        </div>
        <div>
          <p><span class="font-weight-bold">4.2 Votre acceptation des risques.</span>
             Vous reconnaissez que de nombreuses activités comportent des risques inhérents et accepte que, dans les limites autorisées par la loi, vous assumez l'ensemble des risques découlant de votre accès à la plateforme Maisonier et à tout contenu (tel que défini à l'article 10) et de son utilisation, y compris votre séjour dans un
             logement, l'utilisation de tout autre service de location, ou toute autre interaction que vous avez avec d'autres Membres, que ce soit en personne ou en ligne. Cela signifie qu'il vous
            appartient de vous renseigner sur un Service de location pour déterminer s'il vous convient. Par exemple, certains Services de location peuvent comporter un risque de maladie, de
            blessure corporelle, d'invalidité ou de décès, et vous assumez ces risques librement et délibérément en choisissant de participer à ces services.
          </p>
        </div>
        <div>
          <h3 class="font-weight-bold">Conditions relatives aux bailleurs</h3>
          <h4 class="font-weight-bold">5. Accueil du locataire avec Maisonier .</h4>
          <p><span class="font-weight-bold">5.1 Bailleur .</span>
             En tant que bailleur, Maisonier vous offre la possibilité de gérer, partager vos logement, ou autre Service de location avec notre communauté de locataires, et de gagner de l'argent ce faisant. Créer une annonce est très facile. Vous contrôlez la
             façon dont vous accueillez les locataires, et vous fixez votre prix, vos disponibilités et les règles pour chaque Annonce.
          </p>
        </div>
        <div>
          <p><span class="font-weight-bold">5.2 Contrat avec les locataires.</span>
             Lorsque vous acceptez une demande de réservation ou recevez une confirmation de réservation sur la plateforme Maisonier , vous concluez un contrat directement avec le locataire et êtes responsable de la
             prestation du Service de location selon les conditions et au prix spécifiés dans votre annonce. Vous acceptez également de payer les frais applicables tels que les frais de service Maisonier (et les taxes applicables) pour chaque réservation. Maisonier
             Payments déduira les montants dus de votre paiement, à moins que vous et nous ne convenions d'une autre méthode. Toutes les conditions, politiques ou modalités que
             vous incluez dans un contrat supplémentaire avec les locataires doivent être conformes à ces conditions, à nos politiques et aux informations fournies dans votre Annonce, et être divulguées de manière visible dans la description de votre annonce.
          </p>
        </div>
        <div>
          <p><span class="font-weight-bold">5.3 Indépendance des Bailleurs. </span>
             Votre relation avec Flysoft Engineering est celle d'une entité ou d'un individu indépendant et non un employé, agent, coentrepreneur ou partenaire de Flysoft Engineering, sauf que Maisonier Payments agit en tant
             qu'agent de recouvrement des paiements comme décrit dans les conditions de paiement. Flysoft Engineering ne dirige ni ne contrôle votre service de location. Vous
            convenez que vous avez toute latitude pour décider si et quand vous souhaitez fournir des Services de location, à quel prix et à quelles conditions les offrir
          </p>
        </div>
        <div>
          <h4 class="font-weight-bold">6. Gestion de votre annonce .</h4>
          <p><span class="font-weight-bold">6.1 Création et gestion de votre annonce. </span>
              La plateforme Maisonier fournit des outils qui vous permettent de configurer et de gérer facilement vos biens et annonces.Votre Annonce doit inclure des informations complètes et précises sur votre service
              de location, votre prix, les autres frais tels que les frais de ménage, les frais de parking, les cautions, les frais perçus en personne et tous les règlements ou toutes
              les exigences qui s'appliquent à vos locataires ou à votre Annonce. Vous êtes responsable de la mise à jour et de l'exactitude des informations de votre annonce (y
              compris les dates de disponibilité de votre calendrier) et de son contenu (comme les photos) à tout moment. Nous vous recommandons de souscrire une assurance appropriée pour vos services de location. Nous vous suggérons également de lire attentivement les conditions générales de la police, y compris les modalités de couverture et les exclusions. Vous ne pouvez gérer qu'une seule Annonce par
              logement , mais vous pouvez avoir plusieurs Annonces pour un chaque logement si vous en disposez de plusieurs.
          </p>
        </div>
        <div>
          <p><span class="font-weight-bold">6.2 Connaître ses obligations légales.</span>
              Vous êtes tenu de comprendre et de respecter l'ensemble des lois, règlements, réglementations et contrats avec des tiers qui s'appliquent à votre annonce ou vos Services de location. Par exemple, certains
              propriétaires et baux, ou des règlements des copropriétés et d'associations d'appartements en résidence, limitent ou interdisent certaines actions. Certaines villes ont des lois sur l'occupation des sols ou d'autres réglementations qui
              restreignent la location de courte durée de propriétés résidentielles. Certaines juridictions exigent des Bailleurs qu'ils s'enregistrent, obtiennent un permis ou un agrément avant de fournir certains services de location. À certains endroits, les
              services de location que vous voulez proposer pourraient être interdits. Certaines juridictions demandent l'enregistrement des locataires qui séjournent dans votre
              logement. Certaines juridictions ont des lois qui créent des droits de location pour les locataires et des obligations supplémentaires pour les bailleurs. Par exemple, certaines lois peuvent réglementer la location, le contrôle des loyers et l'expulsion pour les séjours de longue durée. Vérifiez la réglementation locale pour connaître les
              règles applicables aux services de location que vous prévoyez d'offrir. Les informations que nous fournissons concernant les exigences légales sont uniquement à titre d'information. Vous devez vérifier vos obligations de manière
              indépendante. Vous êtes responsable du traitement et de l'utilisation des données personnelles des locataires et d'autres personnes conformément aux lois sur la protection de la vie privée et aux présentes conditions, y compris nos règles de
              confidentialité qui s'appliquent aux Bailleurs. Si vous avez des questions sur la façon dont la législation locale s'applique, vous devez toujours consulter un conseiller juridique
          </p>
        </div>
        <div>
          <p><span class="font-weight-bold">6.3 Classement dans les résultats de recherche.</span>
              Le classement des annonces dans les résultats de recherche sur la plateforme Maisonier dépend de plusieurs facteurs, principalement les suivants :
              <ul>
                <li>Paramètres de recherche indiqués par les locataires (par exemple, type de logement, date et durée de location, fourchette de prix)</li>
                <li>Caractéristiques de l'Annonce (par exemple, prix, disponibilité du calendrier, nombre et qualité des images, commentaires, type de Service de la location, statut du bailleur, ancienneté de l'Annonce, popularité moyenne des locataires)</li>
                <li>Expérience de réservation du locataire (par exemple, l'historique des annulations et du recours au service à la clientèle de location, la facilité de la réservation)</li>
                <li>Exigences de location (par exemple, nombre de jours minimum ou maximum, heure limite de réservation)</li> 
                <li>Préférences du locataire (par exemple, logement précédents, annonces enregistrées, lieu d'où le locataire effectue sa recherche)</li>
              </ul>
          </p>
          <p>Les résultats de recherche peuvent apparaître différemment sur notre application mobile et sur notre site web. Maisonier peut permettre aux Bailleurs de promouvoir leurs Annonces dans les résultats de recherche ou ailleurs sur la plateforme Maisonier moyennant des frais supplémentaires. Vous trouverez de plus amples
            informations sur les facteurs qui déterminent le classement de votre annonce dans les résultats de recherche, sur nos programmes de promotion actuels (le cas échéant) et sur la manière dont nous indiquons le contenu promu dans notre Centre d'aide.
          </p>  
        </div>
        <div>
          <p><span class="font-weight-bold">6.4 Vos responsabilités. </span>
              Vous êtes responsable de vos propres actes et omissions et êtes également responsable des actes et omissions de toute personne que vous autorisez à participer à la fourniture de vos Services de location. Vous êtes
              responsable de la tarification et de l'établissement des règles et exigences de votre annonce. Vous devez décrire tous les frais et redevances dans la description de
              votre annonce, mais vous ne pouvez pas percevoir de frais ou redevances supplémentaires en dehors de la plateforme Maisonier , à l'exception de ceux expressément autorisés par notre politique sur les frais perçus en personne. .
              N'encouragez pas les locataires à créer des comptes tiers, à soumettre des commentaires, à fournir leurs coordonnées ou à prendre d'autres mesures en dehors de la plateforme Maisonier , en violation de notre politique hors plateforme.
          </p>
        </div>
        <div>
          <p><span class="font-weight-bold">6.5 Accueil du locataire en tant qu'équipe ou organisation. </span>
             Si vous travaillez avec un co-bailleur ou un bailleur en tant que membre d'une équipe, d'une entreprise ou d'une autre organisation, l'entité et chaque individu qui participe à la fourniture des Services de location sont responsables en tant que bailleur en vertu des présentes
             Conditions. Si vous acceptez des conditions ou si vous concluez des contrats, vous déclarez et garantissez que vous êtes autorisé à conclure des contrats pour votre équipe, votre entreprise ou toute autre organisation et que chaque entité que vous
             utilisez est en règle selon les lois du lieu où elle est établie. Si vous exercez d'autres fonctions, vous déclarez et garantissez être autorisé à exercer ces fonctions. Si vous
             demandez à Flysoft Engineering de transférer une partie de votre versement à un co-bailleur ou à d'autres bailleurs, ou d'envoyer des paiements à un tiers, vous devez être autorisé à le faire. Vous êtes responsable des montants des paiements et de l'exactitude des informations que vous fournissez.
          </p>
        </div>
        <div>
          <p><span class="font-weight-bold">6.6 Votre acceptation des risques.</span>
              Vous reconnaissez que l'accueil de locataire comporte des risques inhérents et acceptez d'assumer l'ensemble des risques découlant de votre accès à la plateforme Maisonier et de son utilisation, de votre offre de Services de location, ou de toute interaction que vous avez avec d'autres Membres que ce soit en personne ou en
              ligne. Vous reconnaissez avoir eu l’occasion d’étudier la plateforme Maisonier et l'ensemble
              des lois, règles, règlements ou obligations pouvant s'appliquer à votre Annonce ou vos Services de location. Vous reconnaissez que vous ne vous fiez à aucun énoncé de droit fait par Flysoft Engineering.
          </p>
        </div>
        <div>
          <h3>7. Annulations, problèmes de location et modifications de réservation.</h3>
          <p><span class="font-weight-bold">7.1 Annulations et problèmes de location.</span>
              En général, si un locataire annule une réservation, le montant qui vous est payé est déterminé par les conditions d'annulation applicables à cette réservation. En tant que bailleur, vous ne devez pas annuler la réservation d'un locataire sans raison valable en vertu de notre Politique
              sur les cas de force majeure ou de la loi applicable. Si vous annulez la réservation d'un locataire sans une telle raison valable, nous pouvons imposer des frais d'annulation et d'autres conséquences. Si : (i) un locataire rencontre un problème de
              location (tel que défini par la Politique de remboursement des locataires), (ii) un cas de force majeure survient, ou (iii) une réservation est annulée en vertu de l'article 13
              des présentes Conditions, le montant qui vous est payé est réduit du montant que nous remboursons ou fournissons au locataire, et de tous les autres frais raisonnables que nous encourons à la suite de l'annulation. Si un locataire reçoit un
              remboursement alors que vous avez déjà été payé, ou si le montant du remboursement et des autres frais engagés par Flysoft Engineering dépasse votre paiement, Flysoft Engineering (via Maisonier Payments) peut récupérer ce montant auprès de vous, y compris en déduisant le remboursement de vos versements futurs.
              Vous acceptez que la Politique de remboursement des locataires , la Politique sur les cas de force majeure et ces conditions prévalent sur les conditions d'annulation que
              vous avez établies dans les situations où elles permettent l'annulation d'une réservation et/ou l'émission de remboursements aux locataires. Si nous prévoyons
              raisonnablement de rembourser un locataire en vertu de l'une de ces politiques, nous pouvons retenir tout versement pour cette réservation jusqu'à ce qu'une décision de remboursement soit prise.
          </p>
        </div>
        <div>
          <p><span class="font-weight-bold">7.2 Modifications de réservation.</span>
               Les bailleurs et les locataires sont responsables de toute modification de réservation qu'ils acceptent de faire sur la plateforme Maisonier ou qu'ils demandent au service à la clientèle Maisonier de faire en leur
              nom, et acceptent de payer tout montant supplémentaire, frais ou taxes associés à une modification de réservation.
          </p>
        </div>
        <div>
          <h3>8. Taxes.</h3>
          <p><span class="font-weight-bold">8.1 Taxes des Bailleurs.</span>
               En tant que bailleur, vous êtes responsable de déterminer et de remplir vos obligations en vertu des lois applicables et de déclarer, collecter, verser ou inclure dans votre prix toute TVA ou autres taxes indirectes, taxes de
               séjour, taxes de tourisme, impôts sur le revenu ou autres taxes applicables (« taxes »).
          </p>
        </div>
        <div>
          <p><span class="font-weight-bold">8.2 Collecte et versement par Flysoft Engineering. </span>
              Dans les juridictions où Flysoft facilite la collecte et/ou la remise des taxes au nom des Bailleurs, vous donnez l'instruction et l'autorisation à Flysoft Engineering de collecter les taxes en votre nom
              et/ou de remettre ces taxes à l'autorité fiscale compétente. Toutes les taxes collectées et/ou remises par Flysoft Engineering sont signalées aux Membres sur leurs relevés de transaction, le cas échéant. Flysoft Engineering peut demander des
              montants supplémentaires aux Membres (y compris en déduisant ces montants des versements futurs) dans le cas où les taxes collectées et/ou remises sont insuffisantes pour remplir pleinement les obligations fiscales de ces Membres, vous acceptez que votre seul recours pour les taxes collectées par Flysoft Engineering
              soit un remboursement de l'autorité fiscale applicable. Vous reconnaissez et acceptez que nous nous réservons le droit, avec notification préalable des Membres concernés, de cesser la collecte et la remise des taxes dans toute juridiction pour quelque raison que ce soit.
          </p>
        </div>
        <div>
          <p><span class="font-weight-bold">8.3 Informations fiscales. </span>
               Dans certaines juridictions, les réglementations fiscales peuvent nous contraindre à collecter et/ou à déclarer des informations fiscales vous concernant, à prélever des taxes sur vos versements, ou les deux. Si vous ne nous
               fournissez pas les documents que nous jugeons suffisants pour justifier une telle obligation de prélever des taxes sur les versements qui vous sont destinés, nous pouvons prélever les versements jusqu'à concurrence du montant requis par la loi,jusqu'à ce que des documents suffisants soient fournis. Vous acceptez que Flysoft Engineering puisse émettre en votre nom des factures ou des documents similaires
               pour la TVA, la taxe sur les produits et services, la taxe sur la consommation ou d'autres taxes pour vos services d'hôte afin que vous, les locataires et/ou leurs
              entreprises puissiez effectuer des déclarations fiscales précises.
          </p>
        </div>
        <div>
          <h2>Conditions Générales</h2>
          <p><span class="font-weight-bold">9. Commentaires. </span>
               Après chaque Service de location, les locataires et les Bailleurs ont la possibilité de se laisser mutuellement un commentaire. Votre commentaire doit être précis et ne
               peut contenir aucun langage discriminatoire, offensant, diffamatoire ou autre qui violerait nos Règles en matière de contenu ou notre Politique sur les commentaires.
               Les commentaires ne sont pas vérifiés par Flysoft Engineering et peuvent être infondés ou trompeurs.
          </p>
        </div>
        <div>
          <p><span class="font-weight-bold">10. Contenu.</span></p>
        </div>
        <div>
          <p>Certaines parties de la plateforme Maisonier vous permettent de fournir des commentaires, du texte, des photos, de l'audio, de la vidéo, des informations et d'autres contenus (collectivement, le «<span> Contenu</span> »). En fournissant du Contenu, sous
             quelque forme et par quelque moyen que ce soit, vous accordez à Flysoft Engineering une licence non exclusive, mondiale, libre de droits, irrévocable,
             perpétuelle, pouvant faire l'objet d'une sous-licence et cessible pour copier, modifier, préparer des œuvres dérivées, distribuer, publier et exploiter autrement ce Contenu, sans limitation. Si le Contenu inclut des informations personnelles, notre Politique de
             confidentialité décrit la façon dont nous utilisons ces informations personnelles. Lorsque Flysoft Engineering paie pour la création d'un Contenu ou facilite sa
             création, Flysoft Engineering peut être propriétaire de ce Contenu, dans quel cas des conditions ou divulgations supplémentaires le préciserons. Vous êtes seul responsable de tout le Contenu que vous fournissez et garantissez que vous en êtes
             le propriétaire ou que vous êtes autorisé à accorder à Flysoft Engineering les droits décrits dans ces conditions. Vous êtes responsable si votre Contenu viole ou enfreint les droits de propriété intellectuelle ou de confidentialité d'un tiers. Le contenu doit être conforme à nos Règles en matière de contenu et à notre Politique de non-discrimination, qui interdisent, entre autres, le contenu discriminatoire, obscène,
             harcelant, trompeur, violent et illégal. Vous acceptez que Flysoft Engineering puisse mettre à disposition des services ou des outils automatisés pour traduire le Contenu et que votre Contenu puisse être traduit en utilisant ces services ou outils. Flysoft
             Engineering ne garantit pas l'exactitude ou la qualité de ces traductions, et il appartient aux Membres de vérifier l'exactitude de ces traductions.
          </p>
        </div>
        <div>
          <p><span class="font-weight-bold">11. Frais.</span>
            Flysoft Engineering peut facturer des frais (et les taxes applicables) aux bailleurs et aux locataires pour l'utilisation de la plateforme Maisonier . Pour en savoir plus sur les frais de service applicables et leur mode de calcul, consultez notre page
            consacrée aux frais de service. Sauf mention contraire sur la plateforme Maisonier , les frais de service ne sont pas remboursables. Flysoft Engineering se réserve le droit de modifier les frais de service à tout moment, et informera les Membres de
            toute modification de frais avant qu'elle n'entre en vigueur. Ces modifications de frais n'auront pas d'effet sur les réservations effectuées avant la date d'entrée en vigueur
            des frais modifiés. Si vous n'êtes pas d'accord avec une modification des frais, vous pouvez résilier le présent contrat à tout moment conformément à l'article 13.2.
          </p>
        </div>
         <div>
          <h3>12. Règles de la plateforme Maisonier .</h3>
          <p><span class="font-weight-bold">12.1 Règles.</span>
              vous devez respecter ces règles et ne devez pas aider ou inciter d'autres personnes à les enfreindre ou à les contourner
          </p>
          <ul>
            <li> Agir avec intégrité et traiter les autres avec respect.
              <ul>
                <li> Ne pas mentir, représenter faussement quelque chose ou quelqu'un, ou prétendre être quelqu'un d'autre.</li>
                <li>Faire preuve de politesse et de respect lorsque vous communiquez ou interagissez avec les autres.</li>
                <li> Respecter notre Politique de non-discrimination et ne pas discriminer ou harceler les autres</li>
              </ul>
            </li>
            <li>Ne pas endommager, pirater, faire de l'ingénierie inverse, compromettre ou altérer la plateforme Maisonier
              <ul><li>Ne pas utiliser de robots, d’indexation, de moissonnage ou autres, ou d’autres moyens automatiques pour accéder à des données ou autre
                  Contenu ou les récupérer sur la plateforme Maisonier ou pour interagir avec cette dernière.</li>
                  <li> Ne pas pirater, contourner, supprimer, altérer ou tenter de circonvenir toute mesure de sécurité ou technologique utilisée pour protéger la plateforme ou le contenu de la plateforme Maisonier .</li>
                  <li>Ne pas déchiffrer, décompiler, désassembler ou faire de l'ingénierie inverse des logiciels ou du matériel utilisés pour fournir la plateforme Maisonier .</li>
                  <li> N'entreprend aucune action qui pourrait perturber ou nuire à la performance ou au bon fonctionnement de la plateforme Maisonier.</li>
              </ul>
            </li>
            <li>Ne pas utiliser la plateforme Maisonier en dehors du cadre des présentes Conditions ou de tout autre contrat établi avec Flysoft Engineering .
              <ul>
                <li> Utiliser les informations personnelles d'un autre Membre uniquement dans la mesure nécessaire pour faciliter une transaction par le biais de la plateforme Maisonier , comme autorisé par les présentes Conditions.</li>
                <li>Ne pas utiliser la plateforme Maisonier , nos outils de messagerie ou les informations personnelles des Membres pour envoyer des messages commerciaux sans le consentement explicite du destinataire.</li>
                <li> Utiliser le Contenu mis à disposition par la plateforme Maisonier uniquement si cela est nécessaire pour vous permettre d'utiliser la plateforme Maisonier en tant que locataire ou Bailleur.</li>
                <li>Ne pas utiliser le Contenu sauf si vous disposez de la permission du propriétaire du Contenu ou si l'utilisation est autorisée par nous dans ces conditions ou dans un autre contrat que vous avez établi avec Flysoft Engineering .</li>
                <li>Ne pas demander, effectuer ou accepter une réservation ou un versement en dehors de la plateforme Maisonier pour éviter de payer des frais, des taxes ou pour toute autre raison. Consulter notre politique sur les frais perçus en personne pour connaître les exceptions.</li>
                <li>Ne pas obliger ou encourager les locataires à ouvrir un compte, laisser un commentaire, répondre à une enquête ou interagir de toute autre manière avec un site web, une application ou un service tiers avant, pendant ou après une réservation, sauf autorisation de Flysoft Engineering .</li>
                <li> Ne pas tenter de manipuler notre algorithme de recherche.</li>
                <li> Ne pas réserver de services de location à moins que vous n'utilisiez effectivement ces services.</li>
                <li> Ne pas utiliser, copier, afficher ou encadrer la plateforme Maisonier , tout Contenu, toute marque Maisonier , ou toute mise en page ou conception sans notre consentement.</li>
              </ul>
            </li>
            <li>Respecter vos obligations légales
              <ul>
                <li>Comprendre et respecter les lois qui s'appliquent à vous, y compris les lois sur la vie privée, la protection des données et l'exportation.</li>
                <li>Si vous nous fournissez les informations personnelles relatives à une autre personne , vous devez le faire dans le respect du droit applicable, vous devez être autorisé à le faire, et vous nous autorisez à traiter ces informations dans le cadre de notre Politique de confidentialité.</li>
                <li>Lire et respecter les présentes conditions, politiques et normes.</li>
                <li> Ne pas organiser ou faciliter des événements ou fêtes non autorisées.Vous êtes légalement responsable pour tout événement ou fête durant votre séjour qui enfreint nos règles pour les évènements et les fêtes telles que référencées dans le présent document.</li>
                <li> Ne pas utiliser le nom, le logo, la marque ou les marques déposées Maisonier ou d'autres personnes sans autorisation.</li>
                <li>Ne pas utiliser ou enregistrer un nom de domaine, un identifiant de réseau social, un nom commercial, une marque, un logo ou tout autre identifiant de source qui pourrait être confondu avec la marque Maisonier .</li>
                <li> Ne pas proposer de services de location qui enfreignent les lois ou les contrats qui vous concernent.</li>
                <li>Ne pas offrir ou solliciter de services de prostitution, ni ne participer à la traite des êtres humains ou la faciliter</li>
              </ul>
            </li>
          </ul>
        </div>
        <div>
          <p><span class="font-weight-bold">12.2 Signalement d'une infraction </span> Si vous pensez qu'un Membre, une Annonce ou du Contenu présente un risque imminent de dommage pour une personne ou un logement, vous devez immédiatement contacter les autorités locales avant de
              contacter Flysoft Engineering. En outre, si vous pensez qu'un Membre, une Annonce ou un Contenu constitue un manquement à nos normes, vous devez faire part de vos préoccupations à Flysoft Engineering . Si vous avez signalé un problème aux autorités locales, Flysoft Engineering peut demander une copie de ce signalement.
              Sauf si la loi l'exige, vous acceptez que nous ne soyons pas tenus de prendre des mesures en réponse à un signalement.
          </p>
        </div>
        <div>
          <p><span class="font-weight-bold">12.3 Notifications de droit d'auteur.</span>  Si vous pensez qu'un Contenu de la plateforme Maisonier viole des droits d'auteur, veuillez nous en informer conformément à notre Politique sur les droits d'auteur.
          </p>
        </div>
        <div>
          <h3>13. Résiliation, suspension et autres mesures.</h3>
          <p><span class="font-weight-bold">13.1 Durée. </span>
              Le contrat entre vous et Flysoft Engineering reflété par ces Conditions est effectif lorsque vous accédez à la plateforme Maisonier (par exemple, pour créer un compte) et reste en vigueur jusqu'à ce que vous ou nous résilions le contrat conformément à ces Conditions.
          </p>
        </div>
         <div>
          <p><span class="font-weight-bold">13.2 Résiliation.</span>
             Vous pouvez résilier le présent contrat à tout moment en nous envoyant <b style="color:rgba(1, 121, 201, 0.842);"> un courriel</b> ou en supprimant votre compte. Flysoft Engineering peut résilier ce contrat et votre compte pour toute raison en vous donnant un préavis de 30 jours par courriel ou en utilisant toute autre coordonnée que vous avez fournie pour votre
             compte. Flysoft Engineering peut également résilier ce contrat immédiatement et sans préavis et cesser de fournir l'accès à la plateforme Maisonier si vous violez ces
             Conditions, vous violez nos politiques, vous violez les lois applicables, ou nous croyons raisonnablement que la résiliation est nécessaire pour protéger Flysoft
             Engineering, ses Membres ou des tiers. Si votre compte est inactif depuis plus de deux ans, nous pouvons résilier votre compte sans préavis.
          </p>
        </div>
         <div>
          <p><span class="font-weight-bold">13.3 Infractions commises par un membre.</span>
            Si vous faites infraction à ces Conditions, nos politiques ou nos normes, si vous faites infraction aux lois applicables, aux règlements ou aux droits de tiers, Flysoft Engineering croit qu'il est
            raisonnablement nécessaire de protéger Maisonier , ses Membres, ou des tiers, Flysoft Engineering peut, avec ou sans notification préalable :
            <ul><li>suspendre ou limiter votre accès à la plateforme Maisonier et/ou à votre compte, ou son utilisation;</li>
                <li>suspendre ou supprimer les Annonces, les commentaires ou tout autre Contenu;</li>
                <li>annuler des réservations en cours ou confirmées</li>
                <li>suspendre ou révoquer tout statut spécial associé à votre compte.</li>
            </ul>
          </p>
          <p>En cas d'infractions mineures ou lorsqu’on jugé nécessaire à la seule discrétion Flysoft Engineering, vous serez informé de toute mesure envisagée par Flysoft Engineering et aurez la possibilité de remédier au problème. Vous pouvez faire appel des mesures que nous avons prises en vertu de cet article en communiquant avec le
              service à la clientèle. Si une réservation est annulée en vertu du présent article, le montant payé au bailleur sera réduit du montant que nous remboursons ou
              fournissons au locataire, ainsi que de tous les autres frais que nous engageons en raison de l'annulation.
          </p>
        </div>
        <div>
          <p><span class="font-weight-bold">13.4 Mandats légaux. </span>
             Flysoft Engineering peut prendre toute mesure qu'il juge raisonnablement nécessaire pour se conformer à la loi applicable, ou à l'ordonnance ou à la demande d'un tribunal, d'un organisme d'application de la loi ou d'une autre agence administrative, ou d'un organisme gouvernemental, y compris les mesures décrites ci-dessus dans l'article 13.3
          </p>
        </div>
        <div>
          <p><span class="font-weight-bold">13.5 Effet de la résiliation.</span>
            Si vous êtes un Bailleur et résiliez votre compte Maisonier , toutes les réservations confirmées seront automatiquement annulées et vos locataires recevront un remboursement complet. Si vous supprimez votre compte
            Maisonier en tant que locataire, toutes les réservations confirmées seront automatiquement annulées et le remboursement éventuel dépendra des conditions d'annulation de l'Annonce. En cas de résiliation de ce contrat, vous n'avez pas droit à une restauration de votre compte ou de votre Contenu. Si votre accès à la plateforme Maisonier ou son utilisation a été limité, si votre compte Maisonier a été suspendu,
            ou si ce contrat a été résilié par nous, vous ne pouvez pas enregistrer un nouveau compte ou accéder à la plateforme ou l'utiliser Maisonier à travers un compte d'un autre Membre.
          </p>
        </div>
        <div>
          <p><span class="font-weight-bold">13.6 Survie. </span>
            Les parties des présentes Conditions qui, de par leur nature, survivent à la résiliation, survivront à la résiliation du présent contrat, y compris les articles 2 à 26.
          </p>
        </div>
        <div>
          <h3 class="font-weight-bold">14. Modification. </h3>
          <p>
            Flysoft Engineering est susceptible de modifier ces Conditions à tout moment. Lorsque nous apporterons des modifications substantielles à ces Conditions, nous publierons les conditions mises à jour sur la plateforme Maisonier et actualiseront la date de « dernière mise à jour » figurant en haut de la page des présentes conditions.
            Nous vous enverrons également toute modification importante par courriel au minimum 30 jours avant leur date d'entrée en vigueur. Si vous désapprouvez les Conditions révisées, vous pourrez résilier immédiatement le présent contrat, comme le prévoient les présentes conditions. Si vous ne résiliez pas votre contrat avant la
            date de prise d'effet des conditions révisées et que vous continuez d'accéder à la plateforme Maisonier ou de l'utiliser, vous serez réputé avoir accepté les Conditions révisées.
          </p>
        </div>
        <div>
          <h3 class="font-weight-bold">15. Règlement des litiges et des réclamations.</h3>
          <p>
            Si un Membre fournit la preuve qu'un autre Membre a endommagé ses biens immobiliers ou personnels ( réclamation pour dommages »), le Membre plaignant peut demander une indemnisation par le biais du Centre de résolution. Si le Membre
            fait remonter une réclamation pour <span> dommages </span>à Flysoft Engineering, l'autre membre aura la possibilité de répondre. Si le Membre répondant accepte de payer, ou Flysoft Engineering détermine à sa seule discrétion qu'il est responsable de la Réclamation pour dommages, Flysoft Engineering par le biais de Maisonier Payments peut
            collecter toutes les sommes requises pour couvrir la réclamation pour dommages du Membre répondant et/ou contre toute caution. Vous acceptez Flysoft Engineering peut chercher à se faire rembourser par vous dans le cadre de toute police
            d'assurance que vous détenez et Flysoft Engineering peut également exercer contre vous tout recours dont il dispose en vertu de la loi applicable. Vous acceptez de
            coopérer de bonne foi, de fournir toute information demandée par Flysoft Engineering , de signer des documents et de prendre d'autres mesures raisonnables
            en relation avec les Réclamations pour dommages, les plaintes des Membres, les réclamations en vertu des polices d'assurance ou d'autres réclamations liées à votre fourniture ou utilisation des Services de location.
          </p>
        </div>
        <div>
          <h3 class="font-weight-bold">16. Rôle Flysoft Engineering. </h3>
          <p>
            Nous offrons une plateforme permettant aux Membres de gérer, publier, d'offrir, de rechercher et de réserver des services de locations. Bien que nous fassions tout notre possible pour nous assurer que nos Membres vivent d'excellentes expériences
            avec Maisonier , nous ne contrôlons pas et ne pouvons pas contrôler la conduite des locataires et des Bailleurs. Vous reconnaissez Flysoft Engineering a le droit, mais n'a
            aucune obligation, de surveiller l'utilisation de la plateforme Maisonier et de vérifier les informations fournies par nos Membres. Par exemple, nous pouvons vérifier le
            Contenu, désactiver son accès, le supprimer ou le modifier : exploiter, sécuriser et améliorer la plateforme Maisonier (y compris pour la prévention des fraudes, l'évaluation des risques, l'enquête et l'assistance utilisateurs);assurer la conformité
            des Membres aux présentes Conditions; se conformer à la loi applicable ou à l'ordonnance ou l'exigence d'un tribunal, de l'application de la loi ou d'un autre organisme administratif ou gouvernemental; traiter le Contenu que nous déterminons comme étant nuisible ou répréhensible; prendre les mesures énoncées
            dans les présentes conditions; maintenir et appliquer tout critère de qualité ou d'admissibilité, y compris en supprimant les annonces qui ne répondent pas aux critères de qualité et d'admissibilité. Les Membres reconnaissent et acceptent
            Flysoft Engineering gère ses politiques (telles que notre Politique sur les cas de force majeure) et ses normes (telles que les critères de base pour les bailleurs), y compris les décisions sur l'opportunité et la manière de les appliquer à une situation particulière, à sa seule discrétion. les Membres s'engagent à coopérer avec Flysoft Engineering et à l'aider en toute bonne foi à fournir à Flysoft Engineering les informations requises et à prendre les mesures raisonnables demandées par Flysoft Engineering, en lien avec toute enquête menée par Flysoft Engineering sur
            l'utilisation ou l'utilisation abusive de la plateforme Maisonie r. Flysoft Engineering n'agit pas en qualité de mandataire d'un quelconque membre, sauf lorsque Maisonier Payments agit en qualité de mandataire de recouvrement comme le prévoient les conditions de paiement.
          </p>
        </div>
        <div>
          <h3 class="font-weight-bold">17. Comptes de membres.</h3>
          <p>
            Vous devez créer un compte pour accéder à de nombreuses fonctionnalités de la plateforme Maisonier et les utiliser. L'inscription n'est autorisée que pour les personnes morales, les sociétés de personnes et les personnes physiques âgées de 18 ans ou plus. Vous déclarez et garantissez que vous n'êtes pas une personne ou
            une entité interdite d’utilisation de la plateforme Maisonier en vertu des lois du Cameroun, de votre adresse de résidence ou de toute autre juridiction applicable. Vous devez fournir des informations exactes, actuelles et complètes lors de
            l'inscription, et tenir à jour les informations relatives à votre compte. Vous ne pouvez pas créer plus d'un compte ou transférer votre compte à quelqu'un d'autre. Il vous appartient de préserver la confidentialité et la sécurité de vos identifiants de compte,
            et il vous est interdit de divulguer vos identifiants à un quelconque tiers. Vous êtes responsable des activités menées sur votre compte et devez immédiatement informer Flysoft Engineering si vous suspectez que vos identifiants ont été perdus, volés, ou que votre compte est autrement compromis. Si la loi applicable le permet,
            nous pouvons, mais n'avons aucune obligation de vous demander de fournir une pièce d'identité ou d'autres informations, entreprendre des vérifications destinées à aider à contrôler votre identité ou vos antécédents, vous comparer à des bases de
            données de tiers ou à d'autres sources et demander des rapports à des prestataires de services, et obtenir des rapports des registres publics des condamnations pénales ou des fichiers de délinquants sexuels ou de leurs équivalents locaux.
          </p>
        </div>
        <div>
          <h3 class="font-weight-bold">18. Exclusion des Garanties.</h3>
          <p>
            Nous fournissons la plateforme Maisonier et tout le contenu « tel quel » sans garanties de quelque nature et nous déclinons toute garantie, explicite ou implicite. Par exemple : nous n'endossons pas ou ne garantissons pas l'existence, la conduite, la performance, la sécurité,
            la qualité, la légalité ou l'adéquation de tout locataires, bailleur, Service de location, annonce ou tiers; nous ne garantissons pas la performance ou la non-interruption de la plateforme
            Maisonier ; et nous ne garantissons pas que les vérifications, les contrôles d'identité ou d'antécédents effectués sur les Annonces ou les Membres (le cas échéant) permettront d'identifier les fautes passées ou de prévenir les fautes futures. Toutes les références à un
            Membre ou à une Annonce « vérifié » (ou tout terme similaire) signifient uniquement que le Membre ou l'Annonce ou Flysoft Engineering a fait l'objet d'une procédure de vérification ou d'identification appropriée, et rien d'autre. Les exclusions de responsabilité figurant dans
            les présentes Conditions générales s'appliquent selon les limites prévues par la loi. Si vous disposez de droits ou de garanties statutaires que nous ne pouvons refuser, la durée de ces
            droits ou garanties statutaires sera limitée dans la mesure maximale autorisée par la loi.
          </p>
        </div>
         <div>
          <h3 class="font-weight-bold">19. Limitations de responsabilité.</h3>
          <p>
            Ni Flysoft Engineering (y compris ses affiliés et son personnel) ni aucune autre partie impliquée dans la création, la production ou la livraison de la plateforme Maisonier ou de tout contenu sera responsable de tout dommage accessoire, spécial, exemplaire ou
            consécutif, y compris les pertes de profits, pertes de données ou pertes de clientèle, interruptions de service, dommages informatiques ou défaillances du système ou le coût des produits ou services de substitution, ou de tout dommage pour les blessures personnelles
            ou corporelles ou la détresse émotionnelle découlant de ou en relation avec ces Conditions générales d'utilisation ou l'incapacité d'utiliser la plateforme Maisonier ou tout Contenu, toute
            communication, interaction ou réunion que vous pourriez avoir avec quelqu'un avec qui vous interagissez ou que vous rencontrez via, ou en conséquence de votre utilisation de la
            plateforme Maisonier , la publication ou la réservation d'une annonce, y compris la fourniture ou l'utilisation des Services de location, qu'elle soit basée sur la garantie, le contrat, le délit
            (y compris la négligence), la responsabilité du produit ou tout autre principe juridique, et indépendamment de si Flysoft Engineering a été informé de la possibilité de tels
            dommages, même si un recours limité prévu dans les présentes conditions est jugé avoir échoué de son objectif essentiel. À l'exception de notre obligation de transmettre les versements aux Bailleurs en vertu de ces
            Conditions générales, ou d'effectuer des versements sous la Garantie de bailleur Maisonier , en aucun cas la responsabilité globale de Flysoft Engineering pour toute réclamation ou
            tout litige découlant de ou en relation avec les présentes Conditions générales, votre interaction avec tout membre, ou votre utilisation ou incapacité à utiliser la plateforme
            Maisonier , tout contenu, ou tout service de locations, ne dépassera : aux locataires, le montant que vous avez payé en tant que locataire au cours de la période de 12 mois précédant l'événement donnant lieu à la responsabilité aux bailleurs, le montant que vous
            avez payé en tant bailleur au cours de la période de 12 mois précédant l'événement donnant lieu à la responsabilité, ou à toute autre personne, une somme déterminée. Ces limitations de responsabilité et dommages sont des éléments fondamentaux du contrat
            entre vous et Flysoft Engineering. Si la loi applicable n'autorise pas les limitations de responsabilité énoncées dans les présentes conditions, les limitations ci-dessus peuvent ne
            pas s'appliquer à votre situation.
          </p>
        </div>
        <div>
          <h3 class="font-weight-bold">20. Indemnisation.</h3>
          <p>
            Dans la mesure maximale permise par la loi applicable, vous acceptez de libérer, défendre (au choix de Flysoft Engineering), indemniser et tenir Flysoft Engineering (y compris Maisonier Payments, d'autres filiales et leur personnel) à l'abri de l'ensemble des
            réclamations, responsabilités, dommages, pertes et dépenses, y compris, mais sans s'y limiter, les frais juridiques et comptables raisonnables, découlant de ou en relation avec votre violation de ces Conditions générales (y compris toute condition supplémentaire ou additionnelle qui s'applique à un produit ou une caractéristique) ou de nos politiques ou
            normes, votre utilisation inappropriée de la plateforme Maisonier , votre interaction avec tout membre, séjour dans un logement, participation à une expérience ou autre service de locations y compris, mais sans s'y limiter, tout préjudice, perte ou dommage (compensatoire,
            direct, accessoire, immatériel ou autre) de toute sorte survenant en relation avec ou à la suite d'une annonce, d'un séjour, d'une participation ou d'une utilisation de ce type, votre manquement, ou votre manquement à nos instructions, à déclarer, collecter ou remettre
            avec précision les taxes, ou votre violation de toute loi ou de tout règlement ou droit de tiers tels que les droits de propriété intellectuelle ou de confidentialité.
          </p>
        </div>
        <div>
          <h3 class="font-weight-bold">21. Entités adjudicatrices .</h3>
          <p>
            En fonction de votre pays de résidence ou d'établissement et de votre activité sur la plateforme Maisonierc, l'annexe 1 ci-dessous indique l'entité juridique avec laquelle vous concluez un contrat. Si nous déterminons par le biais de la plateforme
            Maisonier qu'une entité Flysoft Engineering autre que celle figurant sur l'annexe 1 est responsable d'un produit, d'une caractéristique ou d'une transaction, l'entité Flysoft Engineering ainsi identifiée est votre entité adjudicatrice en ce qui concerne ce
            produit, cette caractéristique ou cette transaction. Si vous changez de pays de résidence ou d'établissement, la société Flysoft Engineering avec laquelle vous concluez un contrat (tel qu'indiqué à l'annexe 1) et la version applicable des
            Conditions générales seront déterminées par votre nouveau pays de résidence ou d'établissement, à partir de la date à laquelle votre pays de résidence ou d'établissement change.
          </p>
        </div>
        <div>
          <h3 class="font-weight-bold">22. Droit applicable et lieu de juridiction au Cameroun. </h3>
          <p>
            Si le Cameroun est votre pays de résidence ou d'établissement, les présentes Conditions générales sont interprétées conformément aux lois du Cameroun, sans tenir compte des dispositions relatives aux conflits de lois. Les procédures
            judiciaires (autres que les actions en recouvrement de petites créances) qui sont exclues de la convention d'arbitrage visée à l'article 23 doivent être engagées devant un tribunal de Yaoundé, à moins que nous ne convenions tous deux d'une autre
            juridiction. Vous et nous reconnaissons la compétence territoriale et personnelle de la juridiction de Yaoundé.
          </p>
        </div>
        <div>
          <h3 class="font-weight-bold">23. Résolution des litiges et Convention d'arbitrage au Cameroun.</h3>
          <p>
            <span class="font-weight-bold">23.1 Application.</span>
            La présente Convention d'arbitrage ne s'applique à vous que même si le Cameroun n’est pas votre pays de résidence ou d'établissement. Si le Cameroun n’est pas votre pays de résidence ou d'établissement, et que vous tentez néanmoins d'intenter une action en justice contre Flysoft Engineering aux Cameroun, la présente
            Convention d'arbitrage s'applique pour déterminer le seuil à partir duquel la présente Section 23 s'applique à vous, et toutes les autres déterminations de seuil, y compris la résidence, la possibilité d'arbitrage, le lieu de juridiction et le droit applicable.
          </p>
        </div>
        <div>
          <p>
            <span class="font-weight-bold">23.2 Vue d'ensemble du processus de résolution des litiges</span>
            Flysoft Engineering s'engage à résoudre les litiges de façon optimale pour les consommateurs. À cette fin, les présentes Conditions générales prévoient un processus en deux parties pour
            les personnes auxquelles s'applique la présente Section 23, une négociation informelle directement avec l'équipe de service à la clientèle de Flysoft Engineering (décrite au paragraphe 23.3, ci-dessous), et si nécessaire un arbitrage obligatoire
            administré par un centre de notre choix . Vous et Flysoft Engineering conservez chacun le droit de demander réparation devant un tribunal acceptant les actions en
            recouvrement de petites créances comme alternative à l'arbitrage.
          </p>
        </div>
        <div>
          <p>
            <span class="font-weight-bold">23.3 Résolution des litiges avant arbitrage et préavis</span>
             Au moins 30 jours avant d'initier une procédure d'arbitrage, vous et Flysoft Engineering vous engagez chacun à notifier par écrit le litige à l'autre partie et à tenter de bonne foi de négocier une résolution informelle du litige. Vous devez envoyer votre avis de litige à Flysoft Engineering en l'adressant par courrier à l'agent Flysoft Engineering pour notification
             <span>: Yaoundé Mvan, Cameroun </span> . Flysoft Engineering enverra son avis de litige à l'adresse courriel associée à votre compte Maisonier . Un avis de litige doit comprendre : le nom de la partie et ses coordonnées, une brève description du litige
             et la mesure demandée. Si les parties ne parviennent pas à résoudre le litige dans le délai de 30 jours, alors seulement l'une ou l'autre des parties peut entamer une procédure d'arbitrage en déposant une demande d'arbitrage écrite auprès de Flysoft
             Engineering et en fournissant une copie à l'autre partie comme le prévoit la loi.
          </p>
        </div>
        <div>
          <p>
            <span class="font-weight-bold">23.4 Convention d'arbitrage</span>
              Vous et Flysoft Engineering acceptez mutuellement que tout litige, réclamation ou différend lié directement ou indirectement à ces Conditions générales , ou à l'applicabilité, la violation, la résiliation, la validité, l'application ou
              l'interprétation de celles-ci, ou à toute utilisation de la plateforme Maisonier, des services de location ou de tout contenu (collectivement, « litiges ») sera réglé par voie d'arbitrage individuel contraignant (la « Convention d'arbitrage »). Si un litige
              existe à savoir si la présente Convention d'arbitrage peut être appliquée ou s'applique à notre litige, Flysoft Engineering et vous convenez que l'arbitre avisera sur ce problème.
          </p>
        </div>
        <div>
          <p>
            <span class="font-weight-bold">23.5 Exceptions à la Convention d'arbitrage.</span>
               Vous et Flysoft Engineering acceptez chacun que les réclamations et/ou causes d'action suivantes sont des exceptions à la Convention d'arbitrage et seront introduites dans une procédure judiciaire devant un tribunal compétent (tel que défini par la Section 22) ; toute réclamation ou cause
               d'action alléguant une contrefaçon, un détournement ou une violation réels ou menacés des droits d'auteur, marques, secrets commerciaux, brevets ou autres droits de propriété intellectuelle d'une partie; toute réclamation ou cause d'action
               demandant une mesure injonctive d'urgence basée sur des circonstances urgentes (par exemple, danger imminent ou commission d'un crime, d'un piratage, d'une cyber-attaque); ou une demande de réparation d'une mesure injonctive publique.
               Vous et Flysoft Engineering convenez que la mesure injonctive publique se forme après la convention d'arbitrage de l'ensemble des réclamations, recours ou causes
               d'action arbitrables, et sera suspendue en attendant le résultat de l'arbitrage conformément à la loi d'arbitrage.
          </p>
        </div>
        <div>
          <p>
            <span class="font-weight-bold">23.6 Règlement d'arbitrage et droit applicable</span>
               Cette Convention d'arbitrage atteste d'une transaction commerciale entre États, et la loi sur l'arbitrage régit toute l'interprétation et l'application de cette disposition sur le fond et la procédure. L'arbitrage sera administré par un centre spécialisé conformément aux règlements
               d'arbitrage pour les litiges avec les consommateurs et/ou d'autres règlements d'arbitrage considérés comme étant applicables en vigueur, et tels que modifiés par les présentes. Pour engager une procédure d'arbitrage, une réclamation écrite
               complétée doit être déposée auprès de spécialisée et fournie à l'autre partie, comme le prévoit la loi.
          </p>
        </div>
        <div>
          <p>
            <span class="font-weight-bold">23.7 Modification du règlement audience/lieu de l'arbitrage.</span>
               Pour rendre l'arbitrage le plus commode possible pour vous, Flysoft Engineering accepte que toute audience arbitrale requise soit conduite, selon votre choix, dans le lieu où vous
               résidez; par téléphone ou vidéoconférence; ou si toutes les parties sont d'accord, simplement par remise de documents à l'arbitre.
          </p>
        </div>
        <div>
          <p>
            <span class="font-weight-bold">23.8 Modification du règlement honoraires et frais d'avocats.</span>
                Vos frais d'arbitrage et votre part de la rémunération de l'arbitre sont régis par la loi et, le cas échéant, limités par le règlement de consommateurs. Si l'arbitre estime que ces coûts sont excessifs, Flysoft Engineering paiera tous les frais et dépenses d'arbitrage. Chaque
                partie peut demander à l'arbitre de condamner l'autre partie à payer les honoraires et frais d'avocat en prouvant que celle-ci a fait valoir une demande reconventionnelle ou une défense non fondée en fait ou en droit, présentée de mauvaise foi ou dans un
               but de harcèlement, ou qui est autrement frivole, comme le permettent le droit applicable et le règlement.
          </p>
        </div>
        <div>
          <p>
            <span class="font-weight-bold">23.9 Décision de l'arbitre. </span>
                La décision de l'arbitre inclura les constatations et conclusions essentielles sur lesquelles se fonde la sentence. Le jugement de la décision arbitrale pourra être rendu par tout tribunal compétent. L'arbitre peut
                accorder toute réparation autorisée par la loi ou le règlement, mais les mesures déclaratoires ou injonctives ne peuvent être accordées qu'au cas par cas et uniquement dans la mesure où elles sont nécessaires pour apporter une réparation justifiée par la demande individuelle du demandeur.
          </p>
        </div>
        <div>
          <p>
            <span class="font-weight-bold">23.10 Renonciation au procès devant jury.</span>
               Vous et Flysoft Engineering reconnaissez et convenez de renoncer tous deux au droit à un procès devant un jury s'agissant de l'ensemble des litiges pouvant être résolus par arbitrage.
          </p>
        </div>
        <div>
          <p>
            <span class="font-weight-bold">23.11 Renonciation aux actions collectives ou recours collectifs.</span>
               Dans les limites les plus étendues autorisées par la loi, vous et Flysoft Engineering reconnaissez et convenez que chacun renonce au droit de participer en tant que plaignant ou en tant que simple membre du groupe à un recours collectif ou à une action collective, un arbitrage collectif, un recours privé devant le procureur général ou toute autre
              procédure représentative ou consolidée. Sauf accord écrit, l'arbitre ne pourra pas regrouper les plaintes de plus d'une partie et ne pourra pas accepter d’arbitrer une
              action collective ou un recours collectif de quelque nature que ce soit. Dans le cas d'une décision judiciaire finale selon laquelle la loi applicable empêche l'application
              de la renonciation contenue dans ce paragraphe pour les réclamations, les motifs de recours ou les réparations demandées, alors cette réclamation, ce motif de recours ou cette réparation demandée, et seulement cette réclamation, ce motif de recours ou cette réparation demandée, sera séparé de cette convention d'arbitrage et sera
              porté devant un tribunal compétent. Dans le cas où une réclamation, un motif de recours ou une réparation demandée est supprimé conformément à ce paragraphe, vous et nous convenons que les réclamations, les motifs de recours ou les
              réparations demandées qui ne sont pas soumis à une convention d'arbitrage seront suspendus jusqu'à ce que les réclamations, les motifs de recours et les réparations demandées arbitrables soient résolus par l'arbitre.
          </p>
        </div>
        <div>
          <p>
            <span class="font-weight-bold">23.12 Divisibilité.</span>
               Sauf dans les cas prévus à l'article 23.11, si une partie de la présente Convention d'arbitrage est jugée illégale ou inapplicable, cette disposition sera supprimée et le reste de la Convention d'arbitrage sera pleinement applicable
          </p>
        </div>
        <div>
          <p>
            <span class="font-weight-bold">23.13 Modifications à la convention d'arbitrage.</span>
               Si Flysoft Engineering modifie cet article 23 après la date à laquelle vous avez accepté ces conditions (ou accepté tout changement ultérieur de ces conditions), vous pouvez rejeter ce changement en nous envoyant une notification écrite (y compris par courriel) dans les 30 jours à
               compter de la date d'entrée en vigueur du changement. Toutefois, refuser une nouvelle modification ne provoquera ni ne modifiera votre consentement antérieur à tout contrat précédemment conclu d'arbitrer tout litige entre vous et Flysoft Engineering (ou votre consentement antérieur à toute modification ultérieure de ce contrat), qui restera applicable à tout litige entre vous et Flysoft Engineering.
          </p>
        </div>
        <div>
          <p>
            <span class="font-weight-bold">23.14 Survie.</span>
               Sauf tel que prévu dans l'article 23.12 et sous réserve de l'article 13.6, cet article 23 survivra à toute résiliation des présentes conditions et continuera à s'appliquer même si vous cessez d'utiliser la plateforme Maisonier ou résiliez votre compte Maisonier .
          </p>
        </div>
        <div>
          <p>
            <span class="font-weight-bold">24. Règlement des litiges, lieu de juridiction et droit applicable dans le reste du monde. </span>
              Si votre pays de résidence ou d'établissement n'est pas au Cameroun, cet article s'applique à vous et ces Conditions seront interprétées conformément à la loi camerounaise. L'application de la Convention des Nations Unies sur les contrats de
              vente internationale de marchandises est exclue. Le choix de la loi n'a pas d'incidence sur vos droits en tant que consommateur, conformément aux règles de protection du consommateur en vigueur dans votre pays de résidence. Si vous agissez en tant que consommateur, vous acceptez de vous soumettre à la juridiction
              non exclusive des tribunaux camerounais. Les procédures judiciaires que vous pouvez engager contre nous en raison ou en rapport avec les présentes conditions ne peuvent être engagées que devant un tribunal situé au Cameroun ou un tribunal compétent dans votre lieu de résidence. Si Flysoft Engineering souhaite faire valoir
              l'un de ses droits contre vous en tant que consommateur, nous ne pouvons le faire que devant les tribunaux de la juridiction dans laquelle vous êtes résident. Si vous agissez en tant qu'entreprise, vous acceptez de vous soumettre à la compétence
              exclusive des tribunaux camerounais.
          </p>
        </div>
        <div>
          <h3>25. Divers.</h3>
          <p>
            <span class="font-weight-bold">25.1 Autres conditions incorporées par référence.</span>
               Les Conditions de la Garantie de bailleur, la Politique de remboursement des voyageurs des locataires, la Politique de contenu, la Politique de non-discrimination, la Politique sur les cas de force majeure, les les normes et valeurs, des politiques et autres conditions reliées avec les
               présentes Conditions générales s'appliquent à votre utilisation de la plateforme Maisonier, sont incorporées par référence, et font partie intégrante de votre contrat avec Flysoft Engineering.
          </p>
        </div>
        <div>
          <p>
            <span class="font-weight-bold">25.2 Interprétation des présentes Conditions générales</span>
               Sauf si elles peuvent être complétées par des conditions, politiques, directives, normes et divulgations de produits supplémentaires, ces Conditions (y compris les éléments incorporés par référence) constituent le contrat intégral entre Flysoft Engineering et vous
               concernant votre accès à la plateforme Maisonier et son utilisation, et remplacent tous les contrats ou accords antérieurs, oraux ou écrits, entre Flysoft Engineering et
               vous. Ces Conditions générales ne confèrent pas et ne sont pas destinées à conférer des droits ou des recours à toute personne autre que vous et Flysoft Engineering. Si
               une disposition des présentes conditions est jugée invalide ou inapplicable, sauf indication contraire dans l'article 23.11 ci-dessus, cette disposition sera supprimée
               et n'affectera pas la validité et l'applicabilité des autres dispositions. Lorsque le mot « sera » est utilisé dans ces conditions générales, il désigne une obligation ayant le
               même sens que « devra »
          </p>
        </div>
        <div>
          <p>
            <span class="font-weight-bold">25.3 Absence de renonciation.</span>
                Le manquement de Flysoft Engineering à faire appliquer tout droit ou toute disposition des présentes Conditions ne constitue pas une renonciation audit droit ou à ladite disposition sauf si nous le reconnaissons et
                le convenons par écrit. Sauf stipulation expresse contraire des présentes Conditions, l'exercice par l'une ou l'autre des parties d'un quelconque recours à sa disposition en
                vertu des présentes Conditions sera sans préjudice des autres recours dont elle dispose en vertu des présentes Conditions ou de la loi.
          </p>
        </div>
        <div>
          <p>
            <span class="font-weight-bold">25.4 Cession.</span>
              Vous ne pouvez pas céder, transférer ou déléguer ce contrat ou vos droits et obligations en vertu des présentes Conditions sans le consentement écrit
              préalable de Flysoft Engineering. Flysoft Engineering peut, sans restriction et à son entière discrétion, céder, transférer ou déléguer le présent contrat et de quelconques droits et obligations au titre des présentes moyennant un préavis de 30 jours.
          </p>
        </div>
        <div>
          <p>
            <span class="font-weight-bold">25.5 Préavis</span>
              Sauf mention contraire, toutes les notifications ou autres communications autorisées ou requises par les présentes, et destinées aux Membres, devront être effectuées électroniquement et remises par Flysoft Engineering par courriel, par notification sur la plateforme Maisonier ou par service
              de messagerie (y compris par message texte et Whatsapp), ou tout autre moyen de contact que nous permettons et que vous fournissez. Si une notification concerne une réservation dans un autre pays, vous acceptez et reconnaissez que ces
              notifications par voie électronique au lieu d'une déclaration écrite, satisfont aux obligations de Flysoft Engineering.
          </p>
        </div>
        <div>
          <p>
            <span class="font-weight-bold">25.6 Services de tiers.</span>
              La plateforme Maisonier peut contenir des liens vers des sites web, des applications, des services ou des ressources de tiers (« <span class="font-weight-bold"> Services de tiers </span>») qui sont soumis à des conditions et des pratiques de confidentialité différentes. Flysoft Engineering n'est pas responsable de tout aspect de ces Services tiers et les liens vers ces Services tiers ne sont pas une approbation.
          </p>
        </div>
        <div>
          <p>
            <span class="font-weight-bold">25.7 Conditions générales de Google. </span>
              Certaines traductions sur la plateforme Maisonier sont fournies par Google. Google décline toute garantie relative aux traductions, explicites ou implicites, y compris toute garantie d'exactitude, de fiabilité et toute garantie implicite de qualité marchande, d'adéquation à un usage particulier
              et de non-contrefaçon. Certaines parties de la plateforme Maisonier utilisent les services de cartographie Google Maps/Google Earth, y compris les API Google Maps. Votre utilisation de Google Maps/Earth est soumise aux Conditions générales supplémentaires de Google Maps/Google Earth.
          </p>
        </div>
        <div>
          <p>
            <span class="font-weight-bold">25.8 Conditions générales d'Apple.</span>
              En utilisant ou en téléchargeant l'application à partir de l'App Store d'Apple, vous acceptez le contrat de licence utilisateur final d'application sous licence d'Apple.
          </p>
        </div>
        <div>
          <p>
            <span class="font-weight-bold">25.9 Contenu de la plateforme Maisonier . </span>
              Le Contenu mis à disposition par la plateforme Maisonier peut être protégé par des droits d'auteur, des marques déposées, et/ou d'autres lois du Cameroun et d'autres pays. Vous reconnaissez que
              tous les droits de propriété intellectuelle pour ce contenu sont la propriété exclusive de Flysoft Engineering et/ou de ses concédants de licence et acceptez de ne
              supprimer, altérer ou ne dissimuler aucun droit d'auteur, marque déposée, marque de service ou autres notifications de droits de propriété. Vous ne pouvez pas utiliser,
              copier, adapter, modifier, créer des œuvres dérivées de, distribuer, concéder une licence sur, vendre, transférer, afficher publiquement, exécuter publiquement,
              transmettre, diffuser ou exploiter de toute autre manière tout Contenu accessible par la plateforme Maisonier sauf dans la mesure où vous êtes le propriétaire légal de ce
              Contenu ou comme expressément autorisé dans les présentes conditions. Sous réserve de votre respect des présentes Conditions, Flysoft Engineering vous octroie une licence limitée, non exclusive, non cessible, révocable, et ne pouvant faire l'objet d'une sous-licence pour télécharger et utiliser l'application sur votre ou vos appareils
              personnels, accéder au Contenu mis à votre disposition sur ou via la plateforme Maisonier , et le consulter, uniquement pour votre usage personnel et non commercial.
          </p>
        </div>
        <div>
          <p>
            <span class="font-weight-bold">25.10 Force majeure. </span>
              Flysoft Engineering ne sera pas responsable de tout retard ou défaut d'exécution résultant de causes hors de son contrôle raisonnable, y compris, mais sans s'y limiter, les catastrophes naturelles, la guerre, le terrorisme, les
              émeutes, les embargos, les actes des autorités civiles ou militaires, incendies, inondations, accidents, pandémies, épidémies ou maladies, grèves ou pénuries de moyens de transport, de carburant, d'énergie, de main-d'œuvre ou de matériaux
          </p>
        </div>
        <div>
          <p>
            <span class="font-weight-bold">25.11 Courriels et messages textes.</span>
               Vous recevrez des communications de notre part en utilisant votre adresse courriel ou d'autres coordonnées que vous aurez fournies pour votre compte Maisonier . L'inscription à des abonnements
              supplémentaires par courriels n'affectera pas la fréquence de ces communications, mais vous devez vous attendre à recevoir des courriels supplémentaires spécifiques aux programmes auxquels vous vous êtes abonnés. Vous pourriez aussi recevoir des
              courriels promotionnels. Aucuns frais ne sont facturés pour ces courriels promotionnels, mais des tarifs de données de tiers pourraient s'appliquer. Vous
              pouvez contrôler les courriels promotionnels que vous recevez en accédant aux préférences de notification dans les paramètres de votre compte. Veuillez noter que vous ne pourrez pas profiter de certaines promotions si vous désactivez certains
              paramètres de communication ou si vous n'avez pas de compte Maisonier. Au Cameroun, si vous acceptez de recevoir des messages textes de notre part, vous serez soumis à nos Conditions relatives aux messages textes.
          </p>
        </div>
        <div>
          <p>
            <span class="font-weight-bold">25.12 Communiquer avec nous. </span>
               Pour toute question concernant les présentes conditions, envoyez-nous un courriel.
          </p>
        </div>                                              
      </b-modal>
  </div>
</template>

<script>
export default {
  name: "Register",
  data: () => ({
    submitted: false,
    nom: null,
    prenom: null,
    tel: null,
    email: null,
    genre: null,
    mdp: null,
	entreprise: null,
  }),
  methods: {
    sendForm() {
      this.submitted = true;
      const data = {
        nom: this.nom,
        prenom: this.prenom,
        tel: this.tel,
        email: this.email,
        genre: this.genre,
		entreprise: this.entreprise,
        mdp: this.mdp,
      };
      axios
        .post("signup", data)
        .then((response) => {
          App.notifySuccess(response.message);
          storage.set("user-email", this.email);
          this.$router.push({ name: "confirm" });
        })
        .catch((error) => {
          this.submitted = false;
          App.notifyError(error.message);
        });
    },
  },
};
</script>

<style scoped>
    .authentication-form{
        width: 80%;
        height:100vh;
        padding: 10% 0;
    }
    .register-top{
      display: flex;
      flex-direction: column;
      align-items: left;
      width: 100%;
    }    
    .register-top .slogan{
      margin: 1rem 0;
      text-align: left;
      width: auto;
      color: #020202;
      font-size: 1.2rem;
      font-weight: bold;
    }
    .register-top .slogan::first-letter{
      font-weight: bold;
    }
    .sign-btn{
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 100%;
      height: auto;
    }
    .sign-btn span{
      font-size: .8em;
    }
    .sign-btn span a{
      color: rgba(1, 121, 201, 0.842);
      font-weight: 600;
    }
    .accept {
      font-size: .75em;
      padding-top: 5px;
      color: #979797;
      line-height: 1rem;
    }
    .accept b{
      color: rgba(1, 121, 201, 0.842);
    }
    .inscription-btn{
      margin-top: .7rem;
      height: 45px;
      width: 100%;
      border-radius: 10px;
      font-weight: bold;
      border: none;
      background: #020202;
      box-shadow: 0 5px 12px rgba(59, 41, 41, 0.089);
      transition: .3s ease;
    }
    .inscription-btn:hover{
      transform: scale(1.03);
      box-shadow: 0 5px 20px rgba(0, 0, 0, 0.199);
    }
    .connect {
      color: #ee3a5b;
    }

    .connect:hover {
      color: #ee3a5b;
      font-weight: bold;
    }

    .maisonier{
      color: #009;
      font-weight: 900;
      font-size: 40px;
    }

    .overlay{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100vh;
      background: rgba(0, 0, 0, 0.35);
      z-index: 9;
    }
    .titre-overlay {
      position: absolute;
      opacity: 1;
      padding: 0;
      margin: 0;
      top: 20%;
      left: 10%;
      z-index: 10;
      max-width: 40rem;
    }
    .titre {
      font-size: 50px;
      font-family: 'Quicksand', sans-serif;
      font-weight: 900;
      color: #fff;
    }
    .description {
      font-size: 25px;
      font-family: 'Quicksand', sans-serif;
      color: #fff;
    }
    .image {
    height: 100vh;
    object-fit: cover;
    }
    .form-group{
      display: flex;
      align-items: center;
      height: 45px;
    }
    .form-group i{
      margin-top: 12px;
    }
    .form-control{
      height: 100%;
      border-radius: 10px;
      box-shadow: 0 3px 8px rgba(0, 0, 0, 0.028);
    }
    .remouve-defalut-class{
      padding: 0;
      margin: 0;
      border-color: transparent !important;
      color:rgba(1, 121, 201, 0.842)!important;
      background-color: inherit !important;
      font-size: inherit !important;
    }
    .form-radio{
      display: flex;
      justify-content: space-between;
    }
    .radio-inline{
      display:flex;
      align-items: center;
    }
    .statut-item{
      font-size: .9rem;
    }
    .remouve-defalut-class{
      height: 30px;
      background: teal;
    }
    .logo{
        width: 50px;
    }
    .statut-client{
      margin-top: 2rem;
    }
    @media only screen and (min-width: 800px){
      .authentication-form{
        width: 80%;
        height:100vh;
        padding: 10% 0 0;
      }
      .register-top .slogan{
        margin: 1rem 0 3rem;
        font-size: 1.9rem;
      }
      .sign-btn{
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: auto;
      }
      .sign-btn span{
        font-size: .8em;
      }
      .sign-btn span a{
        color: rgba(1, 121, 201, 0.842);
        font-weight: 600;
      }
      .form-group{
        height: 50px;
      }
      .inscription-btn{
        margin-top: 0;
        width: 170px;
      }
      .statut-client{
        margin-top: 2rem;
      }
    }
    
</style>